import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import BoardSlug from '../constants/BoardSlug';
import UnitType from '../constants/UnitType';

import BannerImg from '../custom/images/banner.png';
import PlusWhiteIconImg from '../custom/images/icon_plus_white.svg';
import PlusBlackIconImg from '../custom/images/icon_plus_black.svg';
import SsoLoginIconImg from '../custom/images/icon_sso_login.svg';
import CommonLoginIconImg from '../custom/images/icon_common_login.svg';
import MypageIconImg from '../custom/images/icon_mypage.svg';
import LinkItem1IconImg from '../custom/images/icon_link_item1.svg';
import LinkItem2IconImg from '../custom/images/icon_link_item2.svg';
import LinkItem3IconImg from '../custom/images/icon_link_item3.svg';
import LinkItemArrowIconImg from '../custom/images/icon_link_item_arrow.svg';

import * as utils from '../utils';
import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { isDesktop, Desktop, Mobile } = useSizeDetector();

	const [noticeBoard, setNoticeBoard] = useState(null);
	const [postList, setPostList] = useState(null);
	const [bookingList, setBookingList] = useState(null);

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}

		api.getBoards().then((res) => {
			if (res && res.data) {
				let noticeBoard = res.data.find((board) => board.slug === BoardSlug.NOTICE);
				setNoticeBoard(noticeBoard);
				api.getPosts(noticeBoard.id, [`page_size=4`]).then((res) => {
					if (res && res.data) {
						setPostList(res.data.items);
					}
				});
			}
		});
	}, [user]);

	const onClickSsoLogin = () => {
		window.location =
			'https://nice.yu.ac.kr/xn-sso/gw.php?login_type=standalone&site=studio&callback_url=https%3A%2F%2Fstudio.yu.ac.kr%2Flogin%2Fcallback';
	};

	const onClickCommonLogin = () => {
		navigate('/login/general');
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickNoticeBoard = () => {
		navigate('/boards/notice');
	};

	const onClickPostContents = (postId) => {
		navigate(`/boards/${noticeBoard.id}/posts/${postId}`);
	};

	const onClickLinkItem1 = () => {
		window.open('https://nice.yu.ac.kr/lms');
	};

	const onClickLinkItem2 = () => {
		window.open('https://nice.yu.ac.kr/dec');
	};

	const onClickLinkItem3 = () => {
		window.open('https://nice.yu.ac.kr/degree');
	};

	const renderBoardContentsRow = (post, idx) => {
		return (
			<BoardContentsRow key={idx} onClick={() => onClickPostContents(post.id)}>
				<BoardContentsLeft>
					<BoardCategory>{post.category?.name ?? '-'} </BoardCategory>
					<BoardContentTitle>{post.title}</BoardContentTitle>
				</BoardContentsLeft>
				<BoardContentDate>
					{post.created_at ? utils.convertDateToStr(new Date(post.created_at)) : '-'}
				</BoardContentDate>
			</BoardContentsRow>
		);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<MyBookingContainer>
				{bookingList?.items.map((booking, idx) => {
					const bookingUnitType = booking.unit.unit_type_name;
					const locationDetail =
						bookingUnitType === UnitType.ROOM
							? `${booking.unit.name} ${booking.room_item.name}`
							: `${booking.unit.name}`;
					return renderBookingDetailRow(
						idx,
						bookingUnitType,
						booking.approval_status,
						booking.rental_status,
						booking.booking_date.split('~')[0],
						locationDetail
					);
				})}
			</MyBookingContainer>
		);
	};

	const renderBookingDetailRow = (idx, type, approvalStatus, rentalStatus, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={approvalStatus}>
					<span>{ApprovalStatus.toString(approvalStatus)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingType type={type}>{UnitType.toString(type)}</BookingType>
						{type === UnitType.PRODUCT && (
							<RentalStatusBox status={rentalStatus}>
								{RentalStatus.toString(rentalStatus)}
							</RentalStatusBox>
						)}
					</BookingDetailRow>
					<BookingDetailRow column>
						<BookingDetailText>{calendarDetail ?? '-'}</BookingDetailText>
						<BookingLocationDetailText>{locationDetail ?? '-'}</BookingLocationDetailText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<BannerContainer>
					<BoardContainer>
						<BoardTopContainer>
							<BoardTopSubTitle>studio reservation</BoardTopSubTitle>
							<BoardTopTitle>
								<BoardTopTitleBlue>이러닝 콘텐츠 제작</BoardTopTitleBlue>을 위한
								<br />
								최고의 경험을 제공합니다
							</BoardTopTitle>
						</BoardTopContainer>
						<BoardContentsContainer>
							<BoardTitleContainer>
								<BoardSubTitle>공지사항</BoardSubTitle>
								<PlusButtonIcon
									src={isDesktop ? PlusWhiteIconImg : PlusBlackIconImg}
									onClick={() => onClickNoticeBoard()}
								/>
							</BoardTitleContainer>
							<BoardContentsRowContainer>
								{postList?.map((post, idx) => {
									return renderBoardContentsRow(post, idx);
								})}
							</BoardContentsRowContainer>
						</BoardContentsContainer>
					</BoardContainer>
					<MyContainer>
						{user ? (
							<>
								<MyTopContainerLogin>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									{renderMyBooking()}
								</MyTopContainerLogin>
								<MyBottomContainer>
									<CommonLoginButton onClick={onClickMypage}>
										<LoginButtonText>마이페이지</LoginButtonText>
										<LoginButtonIcon src={MypageIconImg} />
									</CommonLoginButton>
								</MyBottomContainer>
							</>
						) : (
							<>
								<MyTopContainerLogout>
									<MyStatusTitle>내 예약현황</MyStatusTitle>
									<MyStatusLoginInfo>로그인 후 이용해 주세요.</MyStatusLoginInfo>
								</MyTopContainerLogout>
								<MyBottomContainer>
									<SsoLoginButton onClick={onClickSsoLogin}>
										<LoginButtonText>통합 로그인</LoginButtonText>
										<LoginButtonIcon src={SsoLoginIconImg} />
									</SsoLoginButton>
									<CommonLoginButton onClick={onClickCommonLogin}>
										<LoginButtonText>일반 로그인</LoginButtonText>
										<LoginButtonIcon src={CommonLoginIconImg} />
									</CommonLoginButton>
								</MyBottomContainer>
							</>
						)}
					</MyContainer>
				</BannerContainer>
			</BannerBackground>
			<LinkContainer>
				<LinkContent>
					<LinkItem>
						<LinkItemLeft onClick={onClickLinkItem1}>
							<LinkItemIcon src={LinkItem1IconImg} />
							<LinkItemText>강의포털시스템</LinkItemText>
						</LinkItemLeft>
						<LinkItemIcon src={LinkItemArrowIconImg} />
					</LinkItem>
					<LinkItem>
						<LinkItemLeft onClick={onClickLinkItem2}>
							<LinkItemIcon src={LinkItem2IconImg} />
							<LinkItemText>스마트교육센터</LinkItemText>
						</LinkItemLeft>
						<LinkItemIcon src={LinkItemArrowIconImg} />
					</LinkItem>
					<LinkItem>
						<LinkItemLeft onClick={onClickLinkItem3}>
							<LinkItemIcon src={LinkItem3IconImg} />
							<LinkItemText>온라인 학위과정</LinkItemText>
						</LinkItemLeft>
						<LinkItemIcon src={LinkItemArrowIconImg} />
					</LinkItem>
				</LinkContent>
			</LinkContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-family: 'Pretendard';
`;

const BannerBackground = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
	}
`;

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const BoardContainer = styled.div`
	width: 474px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px;
	}
`;

const BoardTopContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 343px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media only screen and (max-width: 414.98px) {
		height: 220px;
	}
`;

const BoardTopSubTitle = styled.div`
	font-size: 22px;
	line-height: 26.25px;
	letter-spacing: 14px;
	opacity: 0.5;
	color: #fff;

	@media only screen and (max-width: 414.98px) {
		font-size: 12px;
		line-height: 14.32px;
		letter-spacing: 7px;
	}
`;

const BoardTopTitle = styled.div`
	font-size: 40px;
	font-weight: 600;
	line-height: 47.73px;
	color: #fff;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
	}

	@media only screen and (max-width: 414.98px) {
		font-size: 26px;
		line-height: 31.03px;
	}
`;

const BoardTopTitleBlue = styled.span`
	color: #1d9ad6;
`;

const BoardContentsContainer = styled.div`
	margin-top: 50px;
	width: 100%;
	min-height: 168px;
	border-top: 1px solid rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
		padding: 20px;
		border-top: none;
		border-radius: 10px;
		background-color: #ffffffe5;
	}
`;

const BoardTitleContainer = styled.div`
	padding: 20px 0 15px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		padding: 0 0 15px 0;
	}
`;

const BoardSubTitle = styled.span`
	font-weight: 600;
	font-size: 24px;
	line-height: 28.64px;
	color: #ffffff;

	@media only screen and (max-width: 767.98px) {
		color: #000000;
	}
`;

const PlusButtonIcon = styled.img`
	width: 24px;
	height: 24px;
	object-fit: contain;
	cursor: pointer;
`;

const BoardContentsRowContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const BoardContentsRow = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	gap: 10px;
	cursor: pointer;
`;

const BoardContentsLeft = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	min-width: 0;
`;

const BoardCategory = styled.span`
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.09px;
	color: #1d9ad6;
	width: 70px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const BoardContentTitle = styled.span`
	font-size: 18px;
	font-weight: 400;
	line-height: 21.48px;
	color: #ffffff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 767.98px) {
		color: #333333;
	}

	@media only screen and (max-width: 414.98px) {
		font-size: 16px;
		line-height: 19.09px;
	}
`;

const BoardContentDate = styled.span`
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.09px;
	color: rgba(255, 255, 255, 0.8);

	@media only screen and (max-width: 767.98px) {
		color: #333333cc;
	}

	@media only screen and (max-width: 414.98px) {
		font-size: 13px;
		line-height: 15.51px;
	}
`;

const MyContainer = styled.div`
	width: 300px;
	height: 400px;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 40px 30px;
	background-color: #ffffffd9;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: auto;
		padding: 30px 20px;
		border-radius: 0;
		background-color: white;
	}
`;

const MyTopContainerLogin = styled.div`
	margin-top: 0;
`;

const MyTopContainerLogout = styled.div`
	height: 170px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const MyStatusTitle = styled.span`
	font-size: 30px;
	font-weight: 700;
	line-height: 35.8px;
	color: #000000;
`;

const MyStatusLoginInfo = styled.span`
	font-size: 16px;
	font-weight: 400;
	line-height: 19.09px;
	color: #000000;
`;

const MyBottomContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const LoginButton = styled.div`
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	padding: 0 30px;
	cursor: pointer;
`;

const SsoLoginButton = styled(LoginButton)`
	background-color: #0c3461;
`;

const CommonLoginButton = styled(LoginButton)`
	background-color: #141f32;
`;

const LoginButtonText = styled.span`
	font-size: 22px;
	font-weight: 600;
	line-height: 26.25px;
	color: #ffffff;
`;

const LoginButtonIcon = styled.img``;

const NoBookingList = styled.div`
	width: 100%;
	height: 221px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	color: #000000;
`;

const MyBookingContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 25px;
	}
`;

const MyContentsRow = styled.div`
	height: 73px;
	display: flex;
`;

const BookingStatusBox = styled.div`
	width: 73px;
	height: 73px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	border: 7px solid #1d9ad6;

	span {
		width: 30px;
		text-align: center;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #1d9ad6;
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 7px;
	}

	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const BookingType = styled.div`
	width: 49px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	${(props) => {
		switch (props.type) {
			case UnitType.ROOM:
				return css`
					border: 1px solid #0085ce;
					color: #0085ce;
				`;
			case UnitType.PRODUCT:
				return css`
					border: 1px solid #fc9639;
					color: #fc9639;
				`;
			default:
				return css`
					border: 1px solid #0085ce;
					color: #0085ce;
				`;
		}
	}};
`;

const RentalStatusBox = styled.div`
	margin-left: 5px;
	width: 70px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	${(props) => {
		switch (props.status) {
			case RentalStatus.RENTAL_READY:
				return css`
					background-color: #00c6ba;
				`;
			case RentalStatus.RENTAL_DELAY:
				return css`
					background-color: #e7683f;
				`;
			case RentalStatus.RETURN_DELAY:
				return css`
					background-color: #ff3535;
				`;
			case RentalStatus.RENTAL_COMPLETE:
			case RentalStatus.RETURN_COMPLETE:
				return css`
					background-color: #0d93f4;
				`;
			default:
				return css`
					background-color: #00c6ba;
				`;
		}
	}};
`;

const BookingDetailText = styled.span`
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #333333;
`;

const BookingLocationDetailText = styled.span`
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;

	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const LinkContainer = styled.div`
	width: 100%;
	height: 102px;
	display: flex;
	justify-content: center;
	background-color: #f6f6f6;

	@media only screen and (max-width: 767.98px) {
		padding: 20px;
	}

	@media only screen and (max-width: 414.98px) {
		height: auto;
	}
`;

const LinkContent = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 20px;

	@media only screen and (max-width: 414.98px) {
		flex-direction: column;
		gap: 10px;
	}
`;

const LinkItem = styled.div`
	flex-grow: 1;
	width: 33%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;

	@media only screen and (max-width: 414.98px) {
		width: 100%;
		height: 62px;
	}
`;

const LinkItemLeft = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
`;

const LinkItemIcon = styled.img``;

const LinkItemText = styled.span`
	font-size: 20px;
	font-weight: 600;
	line-height: 23.87px;
	color: #000000;
`;

export default Main;
