const CustomResource = {
	univ_name: '영남대학교',
	site_title: '스튜디오 예약시스템',
	sso_login: '영남대학교 LMS ID/PW로 로그인합니다.',
	address1: '경산캠퍼스 우) 38541 경상북도 경산시 대학로 280',
	address2: '대구캠퍼스 우) 42415 대구광역시 남구 현충로 170',
	contact: '원격교육지원센터 TEL. 053) 810-1959 / FAX 053-810-2038 / EMAIL dge@ynu.ac.kr',
	copyright: 'Copyright ⓒ 2021 Yeungnam University. All Rights Reserved.',
};

export default CustomResource;
